import { Pipe, PipeTransform } from '@angular/core';
import * as numeral from 'numeral'

@Pipe({name: 'numFmt'})
export class NumFmt implements PipeTransform {
  transform(str: number): number {
  	if(str >= 10 || str <= -10 || Number.isInteger(str)){

	    return numeral(str).format('0,0');

	}else if(str >= 1 || str <= -1){

	    return numeral(str).format('0.0');

	}else{

		return numeral(str).format('0.00');	
	}
  	
  }
}