import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgChartjsModule } from 'ng-chartjs';
import { SchoolService } from './shared/school.service';
import { NgbdSortableHeader } from './sortable.directive';

import { HomeComponent } from './home/home.component';
import { NumFmt } from './number-format-pipe';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NgbdSortableHeader,
    NumFmt
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    SlickCarouselModule,
    NgChartjsModule.registerPlugin(),
    HttpClientModule,
    FormsModule
  ],
  providers: [SchoolService],
  bootstrap: [AppComponent]
})
export class AppModule { }
