import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { School } from './school';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
@Injectable({
  providedIn: 'root'
})

export class SchoolService {
  

  // Base url
  baseurl : string = '';

  comsuption_rank_endpoint : string = '';
  generation_rank_endpoint : string = '';
  comsuption_graph_endpoint : string = '';
  auth_token : string = '';
  school_name : string = '';
  httpOptions : object = {};

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.baseurl = environment.base_url;
    this.comsuption_rank_endpoint = '/progressranking/esf';
    this.generation_rank_endpoint = '/generation/esf';
    this.comsuption_graph_endpoint = '/consumption/esf';

    const queryStrings = this.route.snapshot.queryParams

    this.auth_token = queryStrings.a || '';
    this.school_name = queryStrings.n || '';

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization' : 'Bearer '+ this.auth_token
      })
    }
  }

  // Http Headers
  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization' : 'Bearer '+ this.auth_token
  //   })
  // }

  // GET
  GetSchools(): Observable<School> {
   
    return this.http.get<School>(this.baseurl + this.comsuption_rank_endpoint +'?n='+this.school_name, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  // GET
  GetGenerationData(month_range): Observable<School> {
    
    return this.http.get<School>(this.baseurl + this.generation_rank_endpoint + '?months=' + month_range +'&n='+this.school_name, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  // GET
  GetConsumptionGraphData(month_range): Observable<School> {
    return this.http.get<School>(this.baseurl + this.comsuption_graph_endpoint + '?months=' + month_range + '&n=' + this.school_name, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  // Error handling
  errorHandl(error) {
     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }
}
